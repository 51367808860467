import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { NotFoundIllustration } from "@/app/assets/components/NotFoundIllustration";
import { routes } from "@/app/core/http/routes";
import { Page } from "@/app/core/layouts/page/Page";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";

interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  const { back } = useRouter();

  return (
    <Page title="404">
      <Flex center tw="flex-col h-full w-full">
        <NotFoundIllustration width="400" />
        <Text weight="bolder" size="3xl" tw="mt-3 mb-0.5">
          No encontrado
        </Text>
        <Text color="gray-700">Parece que lo que buscas no se encuentra por aquí.</Text>

        {window?.history.state && window?.history.state.idx > 0 ? (
          <button onClick={back} tw="underline mt-1 text-gray-800">
            Volver atrás
          </button>
        ) : (
          <Link href={routes.home} tw="underline mt-1 text-gray-800">
            Volver a inicio
          </Link>
        )}
      </Flex>
    </Page>
  );
};

export default NotFoundPage;
